import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const DailyMeanStandaloneSnapshot = ({ data, CustomToolbar }) => {
    const columns = [
        {
            field: 'date',
            headerName: 'Date',
            sortable: true,
            width: 300
        },
        {
            field: 'avgStandaloneComputedStress',
            headerName: 'Average objective stress (standalone snapshots)',
            sortable: true,
            width: 500
        }
    ]


    return (
        <div>
            <div style={{ textAlign: 'center' }}> Objective stress over time (standalone snapshots) /daily </div>            
            <br></br>
            <div style={{ height: 500, width: '100%' }} className="d-grid gap-2 mt-3 justify-center">
                <DataGrid
                    rows={data}
                    columns={columns}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'date', sort: 'desc' }],
                        },
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }} />
            </div>
        </div>
    );
};

export default DailyMeanStandaloneSnapshot;
